<template>
  <v-container class="py-15">
    <v-card
        class="mx-auto"
        :max-width="options.isLoggingIn ? 344 : 600"
        elevation="24"
    >
      <h2 class="pt-5 mb-3 text-center" v-show="options.isLoggingIn">
        Connexion
      </h2>
      <h2 class="pt-5 mb-3 text-center" v-show="!options.isLoggingIn">
        Inscription
      </h2>

      <v-card-subtitle class="text-center" v-show="options.isLoggingIn && !options.showReinit">
        Pas encore membre ? Quel dommage... <span @click="options.isLoggingIn = !options.isLoggingIn" class="color-orange pointer underline">Inscrivez-vous !</span>
      </v-card-subtitle>

      <v-card-subtitle v-show="options.showReinit">
        <v-btn small color="secondary" @click="options.showReinit= false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-subtitle>

      <v-card-subtitle class="text-center" v-show="!options.isLoggingIn">
        Vous avez déjà un compte ? <span @click="options.isLoggingIn = !options.isLoggingIn" class="color-orange pointer underline">Connectez-vous !</span>
      </v-card-subtitle>

      <v-card-text v-show="options.isLoggingIn && !options.showReinit">
        <v-form ref="form" v-model="valid" lazy-validation @submit="signIn()">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="user.login"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="user.password"
                  :rules="passwordRules"
                  type="password"
                  label="Password"
                  required
                  outlined
                  solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                  text
                  class="mx-auto btn-password-forgotten underline"
                  @click="options.showReinit = true"
              >Mot de passe oublié ?</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                  text
                  class="secondary mx-auto"
                  type="submit"
              >Connexion</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-show="!options.isLoggingIn">
        <v-form ref="form_signup" v-model="validSignUp" lazy-validation dense @submit="signUp()">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="newUser.name"
                  :rules="nameRules"
                  label="Nom complet"
                  required
                  outlined
                  solo
                  hint="Ce nom sera utilisé pour identifier vos textes sur le site. ATTENTION : il n'est pas éditable, choisissez-le bien !"
                  persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="newUser.login"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  solo
                  hint="Nous n'utiliserons JAMAIS votre email à des fins commerciales. Il vous servira de login."
                  persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="newUser.password"
                  :rules="passwordRules"
                  type="password"
                  label="Password"
                  required
                  outlined
                  solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="newUser.passwordBis"
                  :rules="passwordRules"
                  type="password"
                  label="Confirmation Password"
                  required
                  outlined
                  solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                  v-model="acceptRules"
                  :rules="[v => !!v || 'Vous devez accepter le règlement pour continuer !']"
                  label="J'accepte le règlement du site"
                  required
                  color="orange darken-3"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                  type="submit"
                  text
                  class="secondary mx-auto"
              >S'inscrire</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-show="options.showReinit">
        <v-form ref="form_reinit" v-model="validReinit" lazy-validation dense @submit="reinitPassword()">
          <v-row>
            <v-col cols="12">
              <p class="theme text-justify">
                Saisissez votre adresse mail, nous réintialiserons votre mot de passe par mail.
              </p>              
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="reinitLogin"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                  text
                  class="secondary mx-auto"
                  type="submit"
              >Envoyer</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    user: {
      login: null,
      password: null
    },
    newUser: {
      login: null,
      password: null,
      passwordBis: null,
      name: null
    },
    reinitLogin: null,
    options: {
      isLoggingIn: true,
      showReinit: false
    },
    valid: false,
    validSignUp: false,
    validReinit: false,
    acceptRules: false,
    emailRules: [
      v => !!v || 'L\'E-mail est obligatoire',
      v => /.+@.+/.test(v) || 'L\'E-mail doit être valide',
    ],
    passwordRules: [
      v => !!v || 'Le mot de passe est obligatoire.'
    ],
    nameRules: [
      v => !!v || 'Le nom est obligatoire.'
    ]
  }),
  mounted() {

  },
  methods: {
    test () {
      console.log('test');
    },
    signIn() {
      if(this.$refs.form.validate()) {
        this.$http.post('/connect', {user: this.user}).then(response => {
          if(response.data.success) {
            this.$notify({
              group: 'spore',
              title: 'Super !',
              text: 'Ravi de vous revoir :)',
              type: 'success'
            });
            this.user = {
              email: null,
              password: null,
              name: null,
            };
            this.$store.commit('connect');
            this.$router.push('/home');
          } else {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: response.data.message,
              type: 'error'
            });
          }
        }).catch(() => {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Un problème est survenue lors de la connexion.',
            type: 'error'
          });
        });
      }
    },
    signUp() {
      if(this.$refs.form_signup.validate()) {
        if(this.newUser.passwordBis == this.newUser.password) {
          this.$http.post('/createUser', {user: this.newUser}).then(response => {
            if(response.data.success) {
              this.$notify({
                group: 'spore',
                title: 'Super !',
                text: response.data.message,
                type: 'success'
              });
              this.newUser = {
                email: null,
                password: null,
                passwordBis: null,
                name: null,
              };
              this.$store.commit('connect');
              this.$router.push('/home');
            } else {
              this.$notify({
                group: 'spore',
                title: 'Erreur...',
                text: response.data.message,
                type: 'error'
              });
            }
          }).catch(() => {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: 'Un problème est survenue lors de la création du compte.',
              type: 'error'
            });
          });
        } else {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Les mots de passe ne correspondent pas !',
            type: 'error'
          });
        }

      }
    },
    reinitPassword: function() {
      if(this.$refs.form_reinit.validate()) {
        this.$http.post('/reinitPassword', {login: this.reinitLogin}).then(response => {
          if(response.data.success) {
            this.$notify({
              group: 'spore',
              title: 'Super !',
              text: response.data.message,
              type: 'success'
            });
            this.reinitLogin = null;
            this.showReinit = false;
          } else {
            this.$notify({
              group: 'spore',
              title: 'Erreur...',
              text: response.data.message,
              type: 'error'
            });
          }
        }).catch(() => {
          this.$notify({
            group: 'spore',
            title: 'Erreur...',
            text: 'Un problème est survenu.',
            type: 'error'
          });
        });
      }
    }
  }
};
</script>
<style scoped>
.btn-password-forgotten {
  color: rgba(0, 0, 0, 0.6);
}
.underline {
  text-decoration: underline;
}
</style>