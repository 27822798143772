<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Juger</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>Ici, vous êtes tout puissant. Mais n'oubliez pas : un grand pouvoir implique de grandes responsabilités.</p>
        <hr/>
      </v-col>
    </v-row>
    <div v-if="isLoading">
      <v-row>
        <v-col cols="12">
          LOADING...
        </v-col>
      </v-row>
    </div>
    <div v-if="!isLoading">
      <v-row>
        <v-col cols="12">
          <p>
            <v-chip class="mb-2 bkg-orange" label>
              <span class="color-white">Restant : {{ itemsFiltered.length }}</span>
            </v-chip>
          </p>
        </v-col>
      </v-row>
      <v-row v-for="spore in itemsFiltered" :key="spore.id">
        <v-col cols="12">
          <v-sheet
              :elevation="24"
              class="mx-auto pa-5"
          >
            <v-row>
              <v-col cols="12">
                <div class="d-block d-xs-block d-sm-block d-md-flex justify-space-between">
                  <h3 class="mb-0">{{ spore.title }}</h3>
                  <div v-if="spore.tags" class="d-flex gap-1">
                    <v-chip v-for="chip in JSON.parse(spore.tags)" v-bind:key="chip.tag">{{chip.tag}}</v-chip>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <small>{{ spore.created_at }}</small>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="text-justify long-text" v-html="spore.text"></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="text-right long-text">
                  <v-btn small class="bkg-orange mr-2" @click="setWeight(spore.id, 0)"><span class="color-white">0</span></v-btn>
                  <v-btn small class="bkg-orange mr-2" @click="setWeight(spore.id, 1)"><span class="color-white">1</span></v-btn>
                  <v-btn small class="bkg-orange mr-2" @click="setWeight(spore.id, 2)"><span class="color-white">2</span></v-btn>
                  <v-btn small class="bkg-orange mr-2" @click="setWeight(spore.id, 3)"><span class="color-white">3</span></v-btn>
                  <v-btn small class="bkg-orange mr-2" @click="setWeight(spore.id, 4)"><span class="color-white">4</span></v-btn>
                  <v-btn small class="bkg-orange" @click="setWeight(spore.id, 5)"><span class="color-white">5</span></v-btn>
                </p>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    list: null,
    isLoading: true
  }),
  mounted() {
    this.$http.get( '/getJuryList').then(response => {
      if(response.data.success) {
        this.list = response.data.list;
      } else {
        this.$notify({
          group: 'spore',
          title: 'Erreur...',
          text: response.data.message,
          type: 'error'
        });
      }
      this.isLoading = false;
    }).catch(function(response){
      this.$notify({
        group: 'spore',
        title: 'Erreur...',
        text: response.data.message,
        type: 'error'
      });
      this.isLoading = false;
    });
  },
  computed: {
    itemsFiltered: function() {
      return this.list ? this.list.filter((item) => item.is_read != "1") : [];
    }
  },
  methods:{
    setWeight: function(spore_id, weight) {
      this.$http.post( '/setWeight', {'spore_id': spore_id, 'weight': weight}).then(() => {
        this.list.forEach((item) => {
          if(item.id == spore_id) {
            item.is_read = "1";
          }
        });
        window.scrollTo(0, 0);
      }).catch(function(){
      });
    }
  }
};
</script>
<style scoped>
</style>