<template>
  <v-container v-if="contest" class="pa-5">
    <v-row>
      <v-col cols="12">
        <h1>Palmarès {{ contest.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="long-text">Voici le palmarès pour le concours <span class="long-text color-orange">{{ contest.name }}</span> sur le thème : <span class="long-text color-orange" v-html="contest.theme"></span></p>
        <p class="long-text">Aucun texte n'a été corrigé / retouché.</p>
      </v-col>
    </v-row>
    <div class="row" v-show="!contest.palmares.length">
      <div class="col-md-12">
        <p class="long-text theme">Désolé, le palmarès pour le concours <span class="long-text color-orange">{{ contest.name }}</span> n'est pas encore disponible. Revenez bientôt ;)</p>
      </div>
    </div>
    <v-row v-show="!$store.state.isPremium">
      <v-card elevation="24" class="w-100 mb-5 pa-2">
        <div class="d-flex items-center">
          <div class="d-none d-md-flex justify-center" style="min-width: 250px">
            <img src="../assets/premium.png" width="120"/>
          </div>
          <div class="text-center w-100 h-100 mt-5">
            <h3 class="text-center">Envie de lire toutes les Spores du concours ? Pensez à la Taverne Premium !</h3>
            <p class="text-center">C'est 2 euros seulement, mais c'est une grande aide pour la Taverne ;)</p>
            <p class="text-center"><router-link to="/infos-premium">En savoir plus</router-link></p>
            <p class="text-center" v-show="$store.state.isConnected"><v-btn class="bkg-orange" large to="/profil"><span class="color-white">S'abonner</span></v-btn></p>
          </div>
        </div>
      </v-card>
    </v-row>
    <v-row v-show="$store.state.isPremium && $store.state.isConnected && contest.palmares.length">
      <v-card elevation="24" class="w-100 mb-5 pa-2">
        <div class="d-flex items-center">
          <div class="d-none d-md-flex justify-center" style="min-width: 250px">
            <img src="../assets/nain3.png" width="120" style="border-radius: 150px"/>
          </div>
          <div class="text-center w-100 h-100 mt-5">
            <h3 class="text-center">On dirait que vous êtes quelqu'un d'exceptionnel ;)</h3>
          <p class="text-center"><v-btn class="bkg-orange" large :to="'/read-all/' + $route.params.id"><span class="color-white">Lire toutes les Spores du concours</span></v-btn></p>
          </div>
        </div>
      </v-card>
    </v-row>
    <div v-if="contest">
      <v-row class="" v-for="(spore, index) in contest.palmares"
             :key="spore.title" >
        <v-card elevation="24" class="w-100 mb-5">
          <v-col cols="12">
              <h3 class="particletext" :class="{confetti: spore.place=='1'}" v-confet>
                <span v-show="spore.place==1"><img src="https://tavernedesspores.fr/img/gold_cup" width="50px"/></span>
                <span v-show="spore.place==2"><img src="https://tavernedesspores.fr/img/silver_cup" width="50px"/></span>
                <span v-show="spore.place==3"><img src="https://tavernedesspores.fr/img/bronze_cup" width="50px"/></span>
                <span v-show="spore.place>3" class="color-orange fs-30">{{ spore.place }}° - </span>
                {{spore.title}} <span><small>par {{ spore.name }}</small></span>
                <span v-show="spore.place==1"><small> - 10 points - {{ contest.reward }}</small></span>
                <span v-show="spore.place==2"><small> - 8 points</small></span>
                <span v-show="spore.place==3"><small> - 6 points</small></span>
                <span v-show="spore.place==4"><small> - 4 points</small></span>
                <span v-show="spore.place==5"><small> - 3 points</small></span>
                <span v-show="spore.place==6"><small> - 3 points</small></span>
                <span v-show="spore.place==7"><small> - 2 points</small></span>
                <span v-show="spore.place==8"><small> - 2 points</small></span>
                <span v-show="spore.place>8"><small> - 1 point</small></span>
              </h3>
          </v-col>
          <v-col cols="12">
            <div class="long-text text-justify" v-html="spore.text"></div>
          </v-col>
        </v-card>
        <v-card elevation="24" class="w-100 mb-5" v-if="index === 2">
          <v-col cols="12">
            <div class="long-text text-center">
              <a target="_blank" href="https://www.amazon.fr/b?_encoding=UTF8&tag=tds021-21&linkCode=ur2&linkId=3e01e2ac10f0d281176be8736c740e25&camp=1642&creative=6746&node=301061">
                Envie de lire les derniers livres à succès ? Cliquez ici pour consulter la sélection AMAZON !
              </a>
            </div>
          </v-col>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    contest: null
  }),
  directives: {
    confet: {
      inserted: function (el, binding, vnode) {
        let confetticount = (window.innerWidth/100)*10;
        for(let i = 0; i <= confetticount; i++) {
          let new_elem = document.createElement("span");
          new_elem.classList.add("particle", "c" + vnode.context.getRandomInt(1,2));
          new_elem.style.top = vnode.context.getRandomInt(0,10) + '%';
          new_elem.style.left = vnode.context.getRandomInt(0,100) + '%';
          new_elem.style.width = vnode.context.getRandomInt(6,8) + 'px';
          new_elem.style.height = vnode.context.getRandomInt(3,4) + 'px';
          new_elem.style.animationDelay = (vnode.context.getRandomInt(0,50)/10) + 's';
          el.appendChild(new_elem);
        }
      }
    }
  },
  mounted() {
    this.$http.get('/getPalmares/' + this.$route.params.id).then(response => {
      this.contest = response.data;
    }).catch(() => {

    });
  },
  methods: {
    getRandomInt: function(min, max) {
      return Math.floor((Math.random() * max) + min);
    }
  }
};
</script>
<style scoped>
span>small {
  color: #777777;
  font-weight: 400;
  font-family: 'Oswald';
  text-transform: uppercase;
  font-size: 100%;
}
.premium {
  background: #efdba2 !important;
}
</style>