<template>
  <v-container>
    <h1>La Taverne premium ?</h1>
    <div class="text-justify long-text mb-15">
      <v-row>
        <v-col cols="12" class="long-text">
          <p class="long-text">Depuis 2018, la Taverne des Spores propose des concours d'écriture sur des thèmes variés, et ce gratuitement.</p>
          <p class="long-text">Nous donnons donc de notre temps pour encourager les auteurs et leur permettre de garder leur motivation pour l'écriture intacte.</p>
          <p class="long-text">Cependant, l'administration du site et l'organisation des concours a un coût : beaucoup de temps, mais comme le dit le célèbre proverbe, le temps c'est de l'argent :)</p>
          <p class="long-text">C'est pourquoi nous avons pensé à une formule Premium pour nous aider à faire tourner le site et nous encourager en nous montrant votre soutien !</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Mais alors, la Taverne va devenir payante ?</h3>
          <p class="long-text">NON ! L'esprit de la Taverne des Spores n'est pas celui d'une entreprise dont le seul but est de faire de l'argent.</p>
          <p class="long-text font-weight-bold">La Taverne est et restera gratuite.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Du coup, c'est quoi cette histoire de Premium ?</h3>
          <p class="long-text">Le compte Premium est une façon pour vous de soutenir la Taverne ! Voyez cela comme un don mensuel. Mais attention : la Taverne est basée sur des concours dont les vainqueurs sont désignés en se basant sur leurs écrits uniquement.</p>
          <p class="long-text font-weight-bold">Il est donc hors de question de faire payer pour augmenter ses chances de gagner.</p>
          <p class="long-text">En revanche, être membre Premium donnera accès à certains avantages non liés à la participation aux concours !</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>D'accord ! Et concrètement ?</h3>
          <p class="long-text">Devenir membre Premium (en plus de vous apporter toute notre reconnaissance) vous donnera accès à :</p>
          <p class="long-text">
            <ul>
              <li class="long-text">L'intégralité des Spores publiées depuis l'ouverture du site. Vous pourrez lire absolument tout ce qui a été soumis, et vous rendre compte du travail du jury :) En revanche, dans un souci d'équité, vous n'aurez accès aux Spores du dernier concours qu'une fois celui-ci terminé !</li>
              <li class="long-text">Vous pourrez également voir les Spores les mieux notées par le jury. Chaque Spore est notée de 0 à 5 par chaque membre du jury et si besoin sont départagées au cours d'un choix final. Vous pourrez voir quelles Spores ont reçu un "poids" supérieur à 4 (cela vaut aussi pour vos propres Spores).</li>
              <li class="long-text">Ce système de note ayant été mis en place après l'ouverture de la Taverne, pour faciliter le travail du jury, certaines Spores n'auront pas de note : c'est normal, elles datent d'un concours noté "à la main" :)</li>
              <li class="long-text">Vous verrez les statistiques de chaque concours : nombre de Spores soumises, nombre de participant, moyenne des Spores soumises par participant</li>
              <li class="long-text">La liste des avantages Premium pourra grandir au fil du temps (nous avons des idées, vous pouvez nous soumettre les votres :) ).</li>
<!--              <li class="long-text">Vous pourrez voter parmi une liste de thèmes pour le prochain concours. <strong>(Fonctionnalité en cours d'implémentation)</strong></li>-->
            </ul>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Super ! Comment je deviens Premium ?</h3>
          <p class="long-text">Rien de plus simple!</p>
          <p class="long-text">Nous avons mis en place un système d'abonnement à 2€ par mois, que vous pouvez acheter avec votre carte bancaire.</p>
          <p class="long-text">Tout le tunnel de paiement est sécurisé par notre partenaire Stripe, un des leaders du marché du paiement en ligne.</p>
          <p class="long-text">Pour souscrire un abonnement, allez dans votre profil où vous trouverez un bouton pour accéder à l'achat sécurisé.</p>
          <p class="long-text">Une fois l'abonnement mis en place, vous aurez accès aux fonctionnalités Premium immédiatement !</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>A quoi va servir l'argent récolté ?</h3>
          <p class="long-text">Même si nous ne serions pas contre nous payer un yacht, l'objectif du Premium n'est vraiment que de nous soutenir pour assurer le bon fonctionement de la taverne !</p>
          <p class="long-text">L'argent sera dépensé selon la répartition suivante :</p>
          <p class="long-text text-center"><v-img src="../assets/chart.png" width="800" class="mx-auto"></v-img></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Et si je ne veux plus soutenir la Taverne ?</h3>
          <p class="long-text">D'abord, nous serions très tristes :(</p>
          <p class="long-text">Mais une fois Premium nous mettons à disposition un espace client dans lequel vous pourrez voir vos paiements et où vous pourrez également stopper votre abonnement.</p>
          <p class="long-text"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="text-center">Alors, on soutient la Taverne ?</h2>
          <p class="long-text text-center" v-if="$store.state.isConnected"><v-btn class="bkg-orange" to="/profil"><span class="color-white">Aller sur mon profil !</span></v-btn></p>
          <p class="mt-8 text-center" v-if="!$store.state.isConnected"><router-link to="/login" class="long-text">Connectez-vous ou créez un compte pour soutenir la Taverne !</router-link></p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    news: null
  }),
  mounted() {

  },
  methods: {

  }
};
</script>
<style scoped>
.img-news {
  margin: auto;
  max-width: 500px;
}
</style>