import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contests)?_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Archives")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"long-text"},[_vm._v("Vous pouvez accéder ici aux palmarès des concours précédents.")])])],1),_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.isPremium),expression:"!$store.state.isPremium"}]},[_c(VCard,{staticClass:"w-100 mb-5 pa-2",attrs:{"elevation":"24"}},[_c('div',{staticClass:"d-flex items-center"},[_c('div',{staticClass:"d-none d-md-flex justify-center",staticStyle:{"min-width":"250px"}},[_c('img',{attrs:{"src":require("../assets/premium.png"),"width":"120"}})]),_c('div',{staticClass:"text-center w-100 h-100 mt-5"},[_c('h3',{staticClass:"text-center"},[_vm._v("Envie de lire toutes les Spores du concours ? Pensez à la Taverne Premium !")]),_c('p',{staticClass:"text-center"},[_vm._v("C'est 2 euros seulement, mais c'est une grande aide pour la Taverne ;)")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isConnected),expression:"$store.state.isConnected"}],staticClass:"text-center"},[_c(VBtn,{staticClass:"bkg-orange",attrs:{"large":"","to":"/profil"}},[_c('span',{staticClass:"color-white"},[_vm._v("S'abonner")])])],1)])])])],1),_c(VRow,_vm._l((_vm.contests),function(contest,index){return _c(VCol,{key:contest.name,attrs:{"cols":index === 0 ? 12 : 6}},[_c(VCard,{attrs:{"to":'/palmares/' + contest.id}},[_c(VImg,{staticClass:"white--text align-end",attrs:{"src":'https://tavernedesspores.fr/img/' + contest.img,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"200px"}},[_c(VCardTitle,{staticClass:"contest-name font-weight-thin font-italic",domProps:{"innerHTML":_vm._s(contest.name)}}),_c(VCardSubtitle,{staticClass:"contest-theme",domProps:{"innerHTML":_vm._s(contest.theme)}})],1)],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }